import { Auth } from "aws-amplify";
import axios from "axios";
const getAxiosClient = async () => {
  const currentSession = await Auth.currentSession();
  const token = currentSession.getAccessToken().getJwtToken();

  const axiosClient = axios.create({
    baseURL: "https://api.fnzcm-cloudconformity.aws.fnzcapitalmarkets.com",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return axiosClient;
};

export default getAxiosClient;
