import queryString from "query-string";
import { useQuery } from "@tanstack/react-query";
import getAxiosClient from "../getAxiosClient";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import PieChart from "./PieChart";
import Guagechart from "./Gaugechart";
import { Chart, ArcElement } from "chart.js";
import { orderBy } from "lodash";
import { useParams, useLocation } from "react-router-dom";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const chartData = [];

const Report = () => {
  const [value, setValue] = React.useState(0);
  const [innerValue, setInnerValue] = React.useState(0);
  const { search } = useLocation();
  const queryParams = queryString.parse(search);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleInnerChange = (event, newValue) => {
    setInnerValue(newValue);
  };

  const { data, isLoading, isError, status } = useQuery({
    queryKey: ["scores", queryParams.clientName, queryParams.env],
    queryFn: async () => {
      const axiosClient = await getAxiosClient();
      const res = await axiosClient.get(
        `/scores?clientName=${queryParams.clientName}&env=${queryParams.env}`
      );
      return res.data;
    },
  });
  if (isLoading) return "Loading";
  console.log(data);

  return (
    <>
      <Tabs value={value} onChange={handleChange} aria-label="outer tabs">
        <Tab label="Reports" {...a11yProps(0)} />
        <Tab label="Resources" {...a11yProps(1)} />
      </Tabs>
      <CustomTabPanel value={value} index={0}>
        <Tabs
          value={innerValue}
          onChange={handleInnerChange}
          aria-label="basic tabs example"
        >
          <Tab label="June 2023" {...a11yProps(0)} />
          <Tab label="July 2023" {...a11yProps(1)} />
          <Tab label="August 2023" {...a11yProps(2)} />
          <Tab label="September 2023" {...a11yProps(3)} />
          <Tab label="October 2023" {...a11yProps(4)} />
          <Tab label="November 2023" {...a11yProps(5)} />
        </Tabs>

        <CustomTabPanel value={innerValue} index={0}>
          <div className="grid grid-rows-3 grid-flow-col gap-4 items-center align-middle">
            <div className="row-span-3 h-full card flex justify-center items-center">
              <div>
                <h1 className="text-3xl text-center">{`${data[0].clientDisplayName}-${data[0].envDisplayName}`}</h1>
                <PieChart {...{ data: data[0].checks }} />
                <h1 className="flex flex-row text-xl justify-center pt-4">
                  {data[0].score}% Overall Compliance
                </h1>
                <div className="flex justify-center p-8">
                  <a
                    href={`june2023/${data[0].clientDisplayName}-${data[0].envDisplayName}-Conformity-Report.pdf`}
                    download
                  >
                    <button className="bg-black text-white justify-center rounded px-2 py-1">
                      Download Report
                    </button>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-span-1 card">
              <h1 className="flex text-md justify-center pb-2 ">Security</h1>
              <Guagechart
                data={[data[0].securityScore, 100 - data[0].securityScore]}
              />
            </div>
            <div className="col-span-1 card">
              <h1 className="flex text-md justify-center pb-2 ">
                Cost Optimisation
              </h1>
              <Guagechart
                data={[data[0].costOptScore, 100 - data[0].costOptScore]}
              />
            </div>
            <div className="col-span-1 card">
              <h1 className="flex text-md justify-center pb-2 ">
                Operational Excellence
              </h1>
              <Guagechart
                data={[data[0].opeExecScore, 100 - data[0].opeExecScore]}
              />
            </div>
            <div className="col-span-1 card">
              <h1 className="flex text-md justify-center pb-2 ">Reliability</h1>
              <Guagechart data={[data[0].relScore, 100 - data[0].relScore]} />
            </div>

            <div className="col-span-1 card">
              <h1 className="flex text-md justify-center pb-2 ">
                Performance Efficiency
              </h1>
              <Guagechart
                data={[data[0].perfEffecScore, 100 - data[0].perfEffecScore]}
              />
            </div>
            <div className="col-span-1 card">
              <h1 className="flex text-md justify-center pb-2 ">
                Sustainability
              </h1>
              <Guagechart data={[data[0].susScore, 100 - data[0].susScore]} />
            </div>
          </div>
        </CustomTabPanel>
        <CustomTabPanel value={innerValue} index={1}>
          <div className="grid grid-rows-3 grid-flow-col gap-4 items-center align-middle">
            <div className="row-span-3 h-full card flex justify-center items-center">
              <div>
                <h1 className="text-3xl text-center">{`${data[1].clientDisplayName}-${data[1].envDisplayName}`}</h1>
                <PieChart {...{ data: data[1].checks }} />
                <h1 className="flex flex-row text-xl justify-center pt-4">
                  {data[1].score}% Overall Compliance
                </h1>
                <div className="flex justify-center p-8">
                  <a
                    href={`july2023/${data[1].clientDisplayName}-${data[1].envDisplayName}-Conformity-Report.pdf`}
                    download
                  >
                    <button className="bg-black text-white justify-center rounded px-2 py-1">
                      Download Report
                    </button>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-span-1 card">
              <h1 className="flex text-md justify-center pb-2 ">Security</h1>
              <Guagechart
                data={[data[1].securityScore, 100 - data[1].securityScore]}
              />
            </div>
            <div className="col-span-1 card">
              <h1 className="flex text-md justify-center pb-2 ">
                Cost Optimisation
              </h1>
              <Guagechart
                data={[data[1].costOptScore, 100 - data[1].costOptScore]}
              />
            </div>
            <div className="col-span-1 card">
              <h1 className="flex text-md justify-center pb-2 ">
                Operational Excellence
              </h1>
              <Guagechart
                data={[data[1].opeExecScore, 100 - data[1].opeExecScore]}
              />
            </div>
            <div className="col-span-1 card">
              <h1 className="flex text-md justify-center pb-2 ">Reliability</h1>
              <Guagechart data={[data[1].relScore, 100 - data[1].relScore]} />
            </div>

            <div className="col-span-1 card">
              <h1 className="flex text-md justify-center pb-2 ">
                Performance Efficiency
              </h1>
              <Guagechart
                data={[data[1].perfEffecScore, 100 - data[1].perfEffecScore]}
              />
            </div>
            <div className="col-span-1 card">
              <h1 className="flex text-md justify-center pb-2 ">
                Sustainability
              </h1>
              <Guagechart data={[data[1].susScore, 100 - data[1].susScore]} />
            </div>
          </div>
        </CustomTabPanel>
        <CustomTabPanel value={innerValue} index={2}>
          <div className="grid grid-rows-3 grid-flow-col gap-4 items-center align-middle">
            <div className="row-span-3 h-full card flex justify-center items-center">
              <div>
                <h1 className="text-3xl text-center">{`${data[2].clientDisplayName}-${data[2].envDisplayName}`}</h1>
                <PieChart {...{ data: data[2].checks }} />
                <h1 className="flex flex-row text-xl justify-center pt-4">
                  {data[2].score}% Overall Compliance
                </h1>
                <div className="flex justify-center p-8">
                  <a
                    href={`august2023/${data[2].clientDisplayName}-${data[2].envDisplayName}-Conformity-Report.pdf`}
                    download
                  >
                    <button className="bg-black text-white justify-center rounded px-2 py-1">
                      Download Report
                    </button>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-span-1 card">
              <h1 className="flex text-md justify-center pb-2 ">Security</h1>
              <Guagechart
                data={[data[2].securityScore, 100 - data[2].securityScore]}
              />
            </div>
            <div className="col-span-1 card">
              <h1 className="flex text-md justify-center pb-2 ">
                Cost Optimisation
              </h1>
              <Guagechart
                data={[data[2].costOptScore, 100 - data[2].costOptScore]}
              />
            </div>
            <div className="col-span-1 card">
              <h1 className="flex text-md justify-center pb-2 ">
                Operational Excellence
              </h1>
              <Guagechart
                data={[data[2].opeExecScore, 100 - data[2].opeExecScore]}
              />
            </div>
            <div className="col-span-1 card">
              <h1 className="flex text-md justify-center pb-2 ">Reliability</h1>
              <Guagechart data={[data[2].relScore, 100 - data[2].relScore]} />
            </div>

            <div className="col-span-1 card">
              <h1 className="flex text-md justify-center pb-2 ">
                Performance Efficiency
              </h1>
              <Guagechart
                data={[data[2].perfEffecScore, 100 - data[2].perfEffecScore]}
              />
            </div>
            <div className="col-span-1 card">
              <h1 className="flex text-md justify-center pb-2 ">
                Sustainability
              </h1>
              <Guagechart data={[data[2].susScore, 100 - data[2].susScore]} />
            </div>
          </div>
        </CustomTabPanel>
        <CustomTabPanel value={innerValue} index={3}>
          <div className="grid grid-rows-3 grid-flow-col gap-4 items-center align-middle">
            <div className="row-span-3 h-full card flex justify-center items-center">
              <div>
                <h1 className="text-3xl text-center">{`${data[3].clientDisplayName}-${data[3].envDisplayName}`}</h1>
                <PieChart {...{ data: data[3].checks }} />
                <h1 className="flex flex-row text-xl justify-center pt-4">
                  {data[3].score}% Overall Compliance
                </h1>
                <div className="flex justify-center p-8">
                  <a
                    href={`september2023/${data[3].clientDisplayName}-${data[3].envDisplayName}-Conformity-Report.pdf`}
                    download
                  >
                    <button className="bg-black text-white justify-center rounded px-2 py-1">
                      Download Report
                    </button>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-span-1 card">
              <h1 className="flex text-md justify-center pb-2 ">Security</h1>
              <Guagechart
                data={[data[3].securityScore, 100 - data[3].securityScore]}
              />
            </div>
            <div className="col-span-1 card">
              <h1 className="flex text-md justify-center pb-2 ">
                Cost Optimisation
              </h1>
              <Guagechart
                data={[data[3].costOptScore, 100 - data[3].costOptScore]}
              />
            </div>
            <div className="col-span-1 card">
              <h1 className="flex text-md justify-center pb-2 ">
                Operational Excellence
              </h1>
              <Guagechart
                data={[data[3].opeExecScore, 100 - data[3].opeExecScore]}
              />
            </div>
            <div className="col-span-1 card">
              <h1 className="flex text-md justify-center pb-2 ">Reliability</h1>
              <Guagechart data={[data[3].relScore, 100 - data[3].relScore]} />
            </div>

            <div className="col-span-1 card">
              <h1 className="flex text-md justify-center pb-2 ">
                Performance Efficiency
              </h1>
              <Guagechart
                data={[data[3].perfEffecScore, 100 - data[3].perfEffecScore]}
              />
            </div>
            <div className="col-span-1 card">
              <h1 className="flex text-md justify-center pb-2 ">
                Sustainability
              </h1>
              <Guagechart data={[data[3].susScore, 100 - data[3].susScore]} />
            </div>
          </div>
        </CustomTabPanel>
        <CustomTabPanel value={innerValue} index={4}>
          <div className="grid grid-rows-3 grid-flow-col gap-4 items-center align-middle">
            <div className="row-span-3 h-full card flex justify-center items-center">
              <div>
                <h1 className="text-3xl text-center">{`${data[4].clientDisplayName}-${data[4].envDisplayName}`}</h1>
                <PieChart {...{ data: data[4].checks }} />
                <h1 className="flex flex-row text-xl justify-center pt-4">
                  {data[4].score}% Overall Compliance
                </h1>
                <div className="flex justify-center p-8">
                  <a
                    href={`october2023/${data[4].clientDisplayName}-${data[4].envDisplayName}-Conformity-Report.pdf`}
                    download
                  >
                    <button className="bg-black text-white justify-center rounded px-2 py-1">
                      Download Report
                    </button>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-span-1 card">
              <h1 className="flex text-md justify-center pb-2 ">Security</h1>
              <Guagechart
                data={[data[4].securityScore, 100 - data[4].securityScore]}
              />
            </div>
            <div className="col-span-1 card">
              <h1 className="flex text-md justify-center pb-2 ">
                Cost Optimisation
              </h1>
              <Guagechart
                data={[data[4].costOptScore, 100 - data[4].costOptScore]}
              />
            </div>
            <div className="col-span-1 card">
              <h1 className="flex text-md justify-center pb-2 ">
                Operational Excellence
              </h1>
              <Guagechart
                data={[data[4].opeExecScore, 100 - data[4].opeExecScore]}
              />
            </div>
            <div className="col-span-1 card">
              <h1 className="flex text-md justify-center pb-2 ">Reliability</h1>
              <Guagechart data={[data[4].relScore, 100 - data[4].relScore]} />
            </div>

            <div className="col-span-1 card">
              <h1 className="flex text-md justify-center pb-2 ">
                Performance Efficiency
              </h1>
              <Guagechart
                data={[data[4].perfEffecScore, 100 - data[4].perfEffecScore]}
              />
            </div>
            <div className="col-span-1 card">
              <h1 className="flex text-md justify-center pb-2 ">
                Sustainability
              </h1>
              <Guagechart data={[data[4].susScore, 100 - data[4].susScore]} />
            </div>
          </div>
        </CustomTabPanel>
        <CustomTabPanel value={innerValue} index={5}>
          <div className="grid grid-rows-3 grid-flow-col gap-4 items-center align-middle">
            <div className="row-span-3 h-full card flex justify-center items-center">
              <div>
                <h1 className="text-3xl text-center">{`${data[5].clientDisplayName}-${data[5].envDisplayName}`}</h1>
                <PieChart {...{ data: data[5].checks }} />
                <h1 className="flex flex-row text-xl justify-center pt-4">
                  {data[5].score}% Overall Compliance
                </h1>
                <div className="flex justify-center p-8">
                  <a
                    href={`november2023/${data[5].clientDisplayName}-${data[5].envDisplayName}-Conformity-Report.pdf`}
                    download
                  >
                    <button className="bg-black text-white justify-center rounded px-2 py-1">
                      Download Report
                    </button>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-span-1 card">
              <h1 className="flex text-md justify-center pb-2 ">Security</h1>
              <Guagechart
                data={[data[5].securityScore, 100 - data[5].securityScore]}
              />
            </div>
            <div className="col-span-1 card">
              <h1 className="flex text-md justify-center pb-2 ">
                Cost Optimisation
              </h1>
              <Guagechart
                data={[data[5].costOptScore, 100 - data[5].costOptScore]}
              />
            </div>
            <div className="col-span-1 card">
              <h1 className="flex text-md justify-center pb-2 ">
                Operational Excellence
              </h1>
              <Guagechart
                data={[data[5].opeExecScore, 100 - data[5].opeExecScore]}
              />
            </div>
            <div className="col-span-1 card">
              <h1 className="flex text-md justify-center pb-2 ">Reliability</h1>
              <Guagechart data={[data[5].relScore, 100 - data[5].relScore]} />
            </div>

            <div className="col-span-1 card">
              <h1 className="flex text-md justify-center pb-2 ">
                Performance Efficiency
              </h1>
              <Guagechart
                data={[data[5].perfEffecScore, 100 - data[5].perfEffecScore]}
              />
            </div>
            <div className="col-span-1 card">
              <h1 className="flex text-md justify-center pb-2 ">
                Sustainability
              </h1>
              <Guagechart data={[data[5].susScore, 100 - data[5].susScore]} />
            </div>
          </div>
        </CustomTabPanel>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <div>Coming soon</div>
      </CustomTabPanel>
    </>
  );
};

export default Report;
