import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

// Configure Amplify
Amplify.configure({
  Auth: {
    region: awsExports.REGION,
    userPoolId: awsExports.USER_POOL_ID,
    userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID,
  },
});

const loginComponents = {
  Header() {
    return (
      <div className="flex flex-col items-center p-4 justify-center">
        <img src="FNZ_Logo_Black_RGB.png" width={100} height={100} alt="FNZ" />
        <h1 className="text-2xl">Capital Markets</h1>
      </div>
    );
  },
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Authenticator hideSignUp={true} components={loginComponents}>
      {({ signOut, user }) => <App user={user} signOut={signOut} />}
    </Authenticator>
  </React.StrictMode>
);
