import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import Report from "./components/Report";
import Home from "./components/Home";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient({});

function App({ signOut, user }) {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <div className="flex flex-col w-full h-screen">
          <div className="flex h-14 bg-black text-white items-center p-4">
            <Header user={user} signOut={signOut} />
          </div>
          <div className="flex">
            <div className="w-80 bg-gray-200 min-h-[calc(100vh-56px)] p-4">
              <Sidebar />
            </div>
            <div className=" w-full p-4">
              <Switch>
                <Route exact path="/">
                  <Home />
                </Route>
                <Route path="/report">
                  <Report />
                </Route>
              </Switch>
            </div>
          </div>
        </div>
      </Router>
    </QueryClientProvider>
  );
}

export default App;
