import React from "react";
import { Link } from "react-router-dom";
import { orderBy } from "lodash";
import { useQuery } from "@tanstack/react-query";
import getAxiosClient from "../getAxiosClient";
import Report from "./Report";

const Sidebar = () => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ["clients"],
    queryFn: async () => {
      const axiosClient = await getAxiosClient();
      const res = await axiosClient.get("/clients");
      return res.data;
    },
  });
  return (
    <div className="flex flex-col space-y-2">
      {orderBy(data, "clientDisplayName", "asc").map((client, index) => (
        <Link
          to={`/report?clientName=${client.clientName}&env=${client.env}`}
          key={index}
        >
          {client.clientDisplayName} - {client.envDisplayName}
          
        </Link>
      ))}
    </div>
  );
};

export default Sidebar;
