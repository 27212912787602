import React from "react";
import 'chart.js/auto';
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
ChartJS.register(ArcElement, Tooltip, Legend);


const PieChart=({ data })=> {
  return (
    <div className="flex max-h-[400px] justify-center">
      <Pie
        data={{
          labels: ["FAILURE", "SUCCESS"],
          datasets: [
            {
              label: ["Poll"],
              backgroundColor: ["#FF0000", "#00CC00"],

              data: data,
            },
          ],
        }}
        options={{
          plugins: {
            title: {
              display: true,
              position: "top",
              font: 45,
              //fontFamily: "New Times Roman",
              fontColor: "#222222",
              fontStyle: "bold",
              padding: 16,
              lineHeight: 1.2,
            },
            legend: {
              display: false,
            },
            tooltips: {
              callbacks: {
                label: function (tooltipItem) {
                  return tooltipItem.yLabel;
                },
              },
            },
          },
        }}
      />
    </div>
  );
}
export default PieChart;
