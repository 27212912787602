import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
ChartJS.register(ArcElement, Tooltip, Legend);

const Guagechart = ({ data }) => {
  return (
    <div className="flex max-h-[200px] justify-center">
      <Doughnut
        data={{
          labels: ["Suceess", "Failure"],
          datasets: [
            {
              label: "Poll",
              data: data,
              backgroundColor: ["#00CC00", "#FF0000"],
              bordercorlor: ["#00CC00", "#FF0000"],
              circumference: 180,
              rotation: 270,
            },
          ],
        }}
        options={{
          plugins: {
            title: {
              display: true,
              text: `${data[0]}% Compliant`,
              position: "bottom",
              fontSize: 20,
              //fontFamily: "New Times Roman",
              fontColor: "#222222",
              fontStyle: "bold",
              lineHeight: 0.8,
            },
            legend: {
              display: false,
            },
            tooltips: {
              callbacks: {
                label: function (tooltipItem) {
                  return tooltipItem.yLabel;
                },
              },
            },
          },
        }}
      />
    </div>
  );
};

export default Guagechart;
