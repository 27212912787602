import React from "react";
import queryString from "query-string";
import getAxiosClient from "../getAxiosClient";
import { useQuery } from "@tanstack/react-query";
import { getMonth, getYear } from "date-fns";
import PropTypes from "prop-types";
import { useParams, useLocation } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import Box from "@mui/material/Box";
import { ListItemText } from "@mui/material";

const _ = require("underscore");
const clientnames = [];
let headers = [];
const monthshortNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function sortBYMonth(data) {
  const monthshortNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  data.sort(function (a, b) {
    return (
      monthshortNames.indexOf(a.split("-")[0]) -
      monthshortNames.indexOf(b.split("-")[0])
    );
  });
}
const Home = () => {
  const [value, setValue] = React.useState(0);
  const { search } = useLocation();
  const queryParams = queryString.parse(search);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { data, isLoading, isError, status } = useQuery({
    queryKey: ["homeScores"],
    queryFn: async () => {
      const axiosClient = await getAxiosClient();
      const res = await axiosClient.get("/homeScores");
      return res.data;
    },
  });

  if (isLoading) return "Loading";
  const client = new Object();
  for (let item of data) {
    const namesArray = item["sk"].split("-");

    headers.push(
      `${monthshortNames[Number(namesArray[0]) - 1]}-${namesArray[1]}`
    );
  }
  for (let item of data) {
    clientnames.push(`${item.clientDisplayName}-${item.envDisplayName}`);
  }
  const securityTableData = [];
  const costOPtTableData = [];
  clientnames.sort();
  sortBYMonth(headers);
  headers.unshift("Names");

  const clients = new Set(clientnames);

  for (let client of clients) {
    const names = client.split("-");
    if (names[1] === "Prod") {
      const secClientData = [];
      const costOptClientData = [];

      secClientData.push(client);
      costOptClientData.push(client);
      for (let item of data) {
        if (
          item.clientDisplayName === names[0] &&
          item.envDisplayName === names[1]
        ) {
          secClientData.push(item.securityScore);
          costOptClientData.push(item.costOptScore);
        }
      }
      securityTableData.push(secClientData);
      costOPtTableData.push(costOptClientData);
    }
    if (names[1] === "Non Prod") {
      console.log("entered non prod loop");
      const npsecClientData = [];
      const npcostClientData = [];

      npsecClientData.push(client);
      npcostClientData.push(client);
      for (let item of data) {
        if (
          item.clientDisplayName === names[0] &&
          item.envDisplayName === names[1]
        ) {
          npsecClientData.push(item.securityScore);
          npcostClientData.push(item.costOptScore);
        }
      }
      securityTableData.push(npsecClientData);
      costOPtTableData.push(npcostClientData);
    }
  }

  console.log(costOPtTableData);

  let style = "";
  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
      >
        <Tab label="Security Compliance" {...a11yProps(0)} />
        <Tab label="Cost Optimisation" {...a11yProps(1)} />
      </Tabs>
      <CustomTabPanel value={value} index={0}>
        <div>
          <table className="table-auto text-center border border-color-grey px-10 py-10">
            <thead>
              <tr className="text-center border border-color-grey mx-4 my-4 px-2 py-2">
                {_.uniq(headers).map((head) => (
                  <th className="text-center border border-color-grey mx-4 my-4 px-2 py-2">
                    {head}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {securityTableData.map(
                (row) => (
                  (style =
                    row[1] < 80 ||
                    row[2] < 80 ||
                    row[3] < 80 ||
                    row[4] < 80 ||
                    row[5] < 80
                      ? "bg-red-500"
                      : "bg-white-500"),
                  (
                    <tr className="text-center border border-color-grey mx-4 my-4 px-2 py-2">
                      <td className="text-center border border-color-grey mx-4 my-4 px-2 py-2">
                        <div
                          className={
                            (style =
                              row[1] < 80 ||
                              row[2] < 80 ||
                              row[3] < 80 ||
                              row[4] < 80
                                ? "bg-red-500"
                                : "bg-white-500")
                          }
                        >
                          {row[0]}
                        </div>
                      </td>
                      <td className="text-center border border-color-grey mx-4 my-4 px-2 py-2 ">
                        {/* "text-{{ error ? 'red' : 'green' }}-600"> */}
                        <div
                          className={
                            (style =
                              row[1] < 80 ? "bg-red-500" : "bg-white-500")
                          }
                        >
                          {row[1]}%
                        </div>
                      </td>
                      <td className="text-center border border-color-grey mx-4 my-4 px-2 py-2 ">
                        {/* "text-{{ error ? 'red' : 'green' }}-600"> */}
                        <div
                          className={
                            (style =
                              row[2] < 80 ? "bg-red-500" : "bg-white-500")
                          }
                        >
                          {row[2]}%
                        </div>
                      </td>
                      <td className="text-center border border-color-grey mx-4 my-4 px-2 py-2 ">
                        {/* "text-{{ error ? 'red' : 'green' }}-600"> */}
                        <div
                          className={
                            (style =
                              row[3] < 80 ? "bg-red-500" : "bg-white-500")
                          }
                        >
                          {row[3]}%
                        </div>
                      </td>
                      <td className="text-center border border-color-grey mx-4 my-4 px-2 py-2 ">
                        {/* "text-{{ error ? 'red' : 'green' }}-600"> */}
                        <div
                          className={
                            (style =
                              row[4] < 80 ? "bg-red-500" : "bg-white-500")
                          }
                        >
                          {row[4]}%
                        </div>
                      </td>
                      <td className="text-center border border-color-grey mx-4 my-4 px-2 py-2 ">
                        {/* "text-{{ error ? 'red' : 'green' }}-600"> */}
                        <div
                          className={
                            (style =
                              row[5] < 80 ? "bg-red-500" : "bg-white-500")
                          }
                        >
                          {row[5]}%
                        </div>
                      </td>
                      <td className="text-center border border-color-grey mx-4 my-4 px-2 py-2 ">
                        {/* "text-{{ error ? 'red' : 'green' }}-600"> */}
                        <div
                          className={
                            (style =
                              row[6] < 80 ? "bg-red-500" : "bg-white-500")
                          }
                        >
                          {row[6]}%
                        </div>
                      </td>
                    </tr>
                  )
                )
              )}
            </tbody>
          </table>
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <div>
          <table className="table-auto text-center border border-color-grey px-10 py-10">
            <thead>
              <tr className="text-center border border-color-grey mx-4 my-4 px-2 py-2">
                {_.uniq(headers).map((head) => (
                  <th className="text-center border border-color-grey mx-4 my-4 px-2 py-2">
                    {head}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {costOPtTableData.map(
                (row) => (
                  (style =
                    row[1] < 80 || row[2] < 80 || row[3] < 80 || row[4] < 80
                      ? "bg-red-500"
                      : "bg-white-500"),
                  (
                    <tr className="text-center border border-color-grey mx-4 my-4 px-2 py-2">
                      <td className="text-center border border-color-grey mx-4 my-4 px-2 py-2">
                        <div
                          className={
                            (style =
                              row[1] < 80 ||
                              row[2] < 80 ||
                              row[3] < 80 ||
                              row[4] < 80 ||
                              row[5] < 80
                                ? "bg-red-500"
                                : "bg-white-500")
                          }
                        >
                          {row[0]}
                        </div>
                      </td>
                      <td className="text-center border border-color-grey mx-4 my-4 px-2 py-2 ">
                        {/* "text-{{ error ? 'red' : 'green' }}-600"> */}
                        <div
                          className={
                            (style =
                              row[1] < 80 ? "bg-red-500" : "bg-white-500")
                          }
                        >
                          {row[1]}%
                        </div>
                      </td>
                      <td className="text-center border border-color-grey mx-4 my-4 px-2 py-2 ">
                        {/* "text-{{ error ? 'red' : 'green' }}-600"> */}
                        <div
                          className={
                            (style =
                              row[2] < 80 ? "bg-red-500" : "bg-white-500")
                          }
                        >
                          {row[2]}%
                        </div>
                      </td>
                      <td className="text-center border border-color-grey mx-4 my-4 px-2 py-2 ">
                        {/* "text-{{ error ? 'red' : 'green' }}-600"> */}
                        <div
                          className={
                            (style =
                              row[3] < 80 ? "bg-red-500" : "bg-white-500")
                          }
                        >
                          {row[3]}%
                        </div>
                      </td>
                      <td className="text-center border border-color-grey mx-4 my-4 px-2 py-2 ">
                        {/* "text-{{ error ? 'red' : 'green' }}-600"> */}
                        <div
                          className={
                            (style =
                              row[4] < 80 ? "bg-red-500" : "bg-white-500")
                          }
                        >
                          {row[4]}%
                        </div>
                      </td>
                      <td className="text-center border border-color-grey mx-4 my-4 px-2 py-2 ">
                        {/* "text-{{ error ? 'red' : 'green' }}-600"> */}
                        <div
                          className={
                            (style =
                              row[5] < 80 ? "bg-red-500" : "bg-white-500")
                          }
                        >
                          {row[5]}%
                        </div>
                      </td>
                      <td className="text-center border border-color-grey mx-4 my-4 px-2 py-2 ">
                        {/* "text-{{ error ? 'red' : 'green' }}-600"> */}
                        <div
                          className={
                            (style =
                              row[6] < 80 ? "bg-red-500" : "bg-white-500")
                          }
                        >
                          {row[6]}%
                        </div>
                      </td>
                    </tr>
                  )
                )
              )}
            </tbody>
          </table>
        </div>
      </CustomTabPanel>
    </>
  );
};

export default Home;
