import { Link } from "react-router-dom";

const Header = ({ user, signOut }) => {
  return (
    <div className="w-full flex justify-between">
      <div>
        <Link to="/">
          <h1 className="text-2xl">Cloud Conformity Reports</h1>
        </Link>
      </div>
      <div className="flex items-center space-x-4">
        <h1 className="text-l">Hello {user.attributes.email}</h1>
        <button
          className="bg-white text-black rounded px-2 py-1"
          onClick={signOut}
        >
          Sign out
        </button>
      </div>
    </div>
  );
};

export default Header;
